<template>
  <div class="inside-bg jobListPage">
    <div class="container" style="padding-top:100px">
      <div class="row">
        <div class="col-lg-12">
          <nav area-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="/">Home</a></li>
              <li class="breadcrumb-item" :class="job_name == '' ? 'active' : ''" aria-current="page">
                <a v-if="job_name !== ''" href="/jobs-for-freshers">Jobs for freshers</a>
                <p v-else class="pb-0">Jobs for freshers</p>
              </li>
              <li class="breadcrumb-item active" v-if="job_name !== ''" aria-current="page">{{job_name}}</li>
            </ol>
          </nav>
          <div class="m-show mob-search-btn">
            <b-button v-b-toggle.collapse-1 variant="none"><i class="mdi mdi-filter-outline cust-ico"></i></b-button>
          </div>
          <div class="col content searchBox shadow p-4 m-hide">
            <div class="row">
              <div class="col-lg-3 mt-2">
                <input class="form-control fields" v-model="filter.search" name="keyword" type="text"  placeholder="Job title,Keywords, Company..." style="height:55px; !important">
              </div>
              <div class="col-lg-3 mt-2">
                <v-autocomplete v-model="filter.course_id" :items="courses" item-text="text" item-value="id" label="Select course" outlined></v-autocomplete>
              </div>
              <div class="col-lg-3 mt-2">
                <v-autocomplete v-model="filter.city_id" label="Select location" :items="locations" item-text="text" item-value="id" outlined clearable> 
                  <template slot="item" slot-scope="data">
                    <template v-if = typeof(data.item) != 'object'>
                      <v-list-item-content v-text="data.item.text"/>
                    </template>
                    <template v-else>
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.text"/>
                        <v-list-item-sub-title v-html="data.item.group"/>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </div>
              <div class="col-lg-3 mt-2">
                <v-autocomplete v-model="filter.job_role" :items="jobrole" item-text="text" item-value="value" label="Select role" outlined></v-autocomplete>
              </div>
              <div class="col-lg-3 form-group pb-0 text-center" style="margin-left:auto;margin-right:0;">
                <div class="row" style="justify-content:space-evenly">
                  <button class="btn btn-primary search-button" v-on:click="submitFilter"><i class="mdi mdi-magnify text-white"></i> Search</button>
                  <span class="d-flex align-center" v-on:click="clearFilter" style="color: #0054ad;cursor: pointer;"><u>Reset</u></span>
                </div>
              </div>
            </div>
          </div>
          <!-- mobile search bar -->
          <b-collapse id="collapse-1" >
            <div class="col content shadow m-searchBox p-4">
              <div class="row">
                <div class="col-lg-12">
                  <input class="form-control fields" v-model="filter.search" name="keyword" type="text"  placeholder="Keyword">
                </div>
                <div class="col-lg-12">
                  <v-autocomplete v-model="filter.course_id" :items="courses" item-text="text" item-value="id" label="Select course" outlined>
                    <!-- <template slot="item" slot-scope="data">
                      <template v-if = typeof(data.item) != 'object'>
                        <v-list-item-content v-text="data.item.text"/>
                      </template>
                      <template v-else>
                        <v-list-item-content>
                          <v-list-item-title v-html="data.item.text"/>
                          <v-list-item-sub-title v-html="data.item.group"/>
                        </v-list-item-content>
                      </template>
                    </template> -->
                  </v-autocomplete>
                </div>
                <div class="col-lg-12">
                  <v-autocomplete v-model="filter.city_id" label="Select location" :items="locations" item-text="text" item-value="id" outlined clearable> 
                    <template slot="item" slot-scope="data">
                      <template v-if = typeof(data.item) != 'object'>
                        <v-list-item-content v-text="data.item.text"/>
                      </template>
                      <template v-else>
                        <v-list-item-content>
                          <v-list-item-title v-html="data.item.text"/>
                          <v-list-item-sub-title v-html="data.item.group"/>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </div>
                <div class="col-lg-12 form-group pb-0 text-center">
                  <button class="btn btn-primary fields btn-block mb-2" style="line-height: 1.50" v-on:click="submitFilter"><i class="mdi mdi-magnify"></i> Search</button><br>
                  <button  class="btn btn-outline-primary fields btn-block mt-2" v-on:click="clearFilter"><strong>Reset</strong></button >
                </div>
              </div>
            </div>
          </b-collapse>
          <!-- mobile search bar end -->
        </div>
      </div>
      <div class="row" v-if="top_content">
        <div class="col-md-12 top-content">
          <div class="col content shadow" v-html="top_content"></div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3 order-md-2 m-hide">
          <div class="col content shadow">
            <div class="card filterBox">
              <article class="filter-group">
                <header class="card-header">
                  <p class="mb-0"><i class="mdi mdi-tune"></i> Filters </p>
                </header> 
              </article>
              <article class="filter-group">
                <header class="card-header">
                  <span data-toggle="collapse" style="text-decoration:none !important;color: #0054ad;cursor: pointer;" data-target="#collapse_aside1" data-abc="true" aria-expanded="false" class="collapsed"> 
                    <i class="text-dark icon-control mdi mt-0" :class="{'mdi-plus':!showJobrole, 'mdi-minus':showJobrole}" @click="showJobrole = !showJobrole"></i>
                    <div style="font-size:1rem;" class="text-dark mb-1">Job role</div>
                  </span> 
                </header>
                <div class="filter-content collapse" :class="{'collapsed show': showJobrole}" id="role_filter" style="">
                  <div class="card-body filterInput pt-0">
                    <input type="text" id="input_role" name="role" value="" class="form-control" placeholder="Search" @input="filterRole" @click="f_jobrole=jobrole" >
                    <div v-if="f_jobrole.length" style="height:200px;overflow-y:scroll;">
                      <ul class="list-group pl-0">
                        <li class="list-group-item" style="cursor:pointer;" v-for="role in f_jobrole" :value="role.value" :key="role.value" @click="setRoleFilter">{{role.text}}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </article>
              <article class="filter-group">
                <header class="card-header"> 
                  <span data-toggle="collapse" style="text-decoration:none !important;color: #0054ad;cursor: pointer;" data-target="#collapse_aside2" data-abc="true" aria-expanded="false" class="collapsed">
                    <i class="text-dark icon-control mdi mdi-plus mt-0" :class="{'mdi-plus':!showSalary, 'mdi-minus':showSalary}" @click="showSalary = !showSalary"></i>
                    <div style="font-size:1rem;" class="text-dark mb-1">Salary(Monthly) </div>
                  </span>
                </header>
                <div class="filter-content collapse" :class="{'collapsed show': showSalary}" id="salary_filter" style="">
                  <div class="card-body filterInput pt-0">
                    <input type="text" name="salary" class="form-control" placeholder="Search" @blur="setSalaryFilter">
                  </div>
                </div>
              </article>
              
            </div>
          </div>
        </div>
        <div class="col-md-9 order-md-1 ">
          <div class="content myJobs shadow ">
            <div v-if="loading">
              <content-loader viewBox="0 0 476 124" primaryColor="#f3f3f3" secondaryColor="#cccccc" v-if="loading">
                <rect x="0" y="8" rx="3" ry="3" width="88" height="10" />
                <rect x="0" y="56" rx="3" ry="3" width="410" height="6" />
                <rect x="0" y="72" rx="3" ry="3" width="380" height="6" />
                <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
              </content-loader>
            </div>
            <div v-else>
              <div class="jobsHeader">

                <h1>Jobs for freshers</h1>
                <h2 class="text-secondary" style="font-size:1rem">Apply for fresher jobs from major companies</h2>
              </div>
              <div class="row" v-if="jobs.length">
                <div class="col-md-12 mb-2" v-for="(job) in jobs" :key="job.job_id">
                  <div class="card">
                    <div class="card-body jobBox">
                        <div class="row">
                          <div class="col-md-12">
                            <a class="mob-applay-btn m-show" :href="'/jobs/'+job.param"></a>
                            <div style="font-size:1rem;" class="jobTitle mb-1">
                              <a style="color: #0054ad;cursor: pointer;" class="text-decoration-none" :href="'/jobs/'+job.param">{{job.job_designation}}</a>
                              <span class="badge badge-primary float-right" v-if="job.prem_stat"><i class="mdi mdi-crown"></i> Premium</span>
                            </div>
                              <p v-if="job.company_name">
                                <span class="jobIcon"><img :src="$S3_url+'/Assets/images/company.svg'" alt="company name" title="company name" width="18"></span>
                                <span>{{job.company_name}}</span>
                              </p>
                              <p v-if="job.courses" class="row"> 
                                <span class="jobIcon"><img :src="$S3_url+'/Assets/images/collage.svg'" alt="education" title="education" width="23"></span><span v-for="(course , index) in job.courses" :key="index">{{course.name}}<span v-if="job.courses.length != index+1">,&nbsp;</span></span>
                              </p>
                              <p v-if="job.cities" class="row">
                                <span class="jobIcon"><img :src="$S3_url+'/Assets/images/location.svg'" alt="location" title="location" width="22"></span><span v-for="(city, index) in job.cities" :key="index">{{city}}<span v-if="job.cities.length != index+1">,&nbsp;</span></span>
                              </p>
                              <p  v-if="!job.salary_flag">
                                <span class="jobIcon"><img :src="$S3_url+'/Assets/images/money.svg'" alt="salary" title="salary" width="21"></span>
                                <span v-if="!job.salary_range_from || job.salary_range_from == 0">Not disclosed</span>
                                <span v-else><span v-if="job.salary_range_from">Rs. </span>{{job.salary_range_from}}<span v-if="job.salary_range_from&&job.salary_range_to"> - </span><span v-if="job.salary_range_to">Rs. </span>{{job.salary_range_to}} per month</span>
                              </p>
                              <!-- <p  v-if="job.salary_flag">
                                <span class="jobIcon"><img :src="$S3_url+'/Assets/images/money.svg" 'alt="salary" title="salary" width="21"></span>
                                Not Disclosed
                              </p> -->
                              <p>
                                <span class="jobIcon"><img :src="$S3_url+'/Assets/images/calander.svg'" alt="last date to apply" title="Last date to apply" width="20"></span>
                                <span>last date to apply: {{job.last_date}}</span> 
                              </p> 
                              <div class="jobButton m-hide">
                                <a class="btn btn-outline-primary mobright" :href="'/jobs/'+job.param">View & Apply</a>
                              </div>
                          </div>
                        </div>
                      </div> 
                  </div> 
                </div>
              </div>
              <div v-if="!jobs.length" class="col-md-12">
                <div class="container text-center mt-5 mb-5" style="color:#afafaf;">
                  <p style="font-size:1.25rem !important;font-weight:500;"><strong>There are no jobs now exactly matching your request.<br>Request to modify your search.</strong></p>
                  <p>You can find the latest jobs published below.</p>
                  <p v-if="!isLogin">For matching job alerts <a href="">Register now.</a></p>
                </div>
              </div>
            </div>
          </div>
          <div class="col mt-4" v-if="jobs.length && jobs.length < meta.total && jobs.length !== meta.total">
            <div class="loadMorebtn blueTXT" @click="loadMore()">⟳ Load more</div>
            <!-- <v-pagination
              v-model="page"
              :length="length"
              class="float-right"
              @input="handlePageChange"
              ></v-pagination> -->
          </div>
        </div>
      </div>
        <div class="p-3 mt-10">
          <div style="font-size:1.25rem;font-weight:500;" class="mb-1"><strong>Fresher Jobs</strong></div>
          <p>Jobs for freshers page lists freshers &amp; entry-level jobs from major companies in India. The most common <a href="https://en.wikipedia.org/wiki/Industry" target="_blank">industries</a> that we are publishing jobs for freshers are Information technology, sales, marketing, customer service, consulting, BFSI, banking, core engineering, defence, data entry, call centre j etc. The most common locations for jobs for freshers in India are in the major metropolitan areas such as Mumbai, Delhi, Bangalore, Chennai and Hyderabad. Also, you can find jobs near your city anywhere in India from tier2, 3 and smaller cities also. We are publishing full-time, part-time, freelance, gig, walk-in drives, work from home & hybrid job posts. Nowadays there are various job opportunities for freshers in India to find work in their desired fields and locations. Finding jobs for freshers in India can be difficult as the market is extremely competitive, and many companies are not interested in hiring inexperienced workers. However, there are certain things that freshers can do to increase their chances of getting hired.</p>
          <h3 class="mt-5" style="font-size:1.25rem"><strong>Best site for fresher jobs</strong></h3>
          <p>Here is the best platform for finding the <a href="https://www.hireclap.com" target="_blank">latest jobs</a> for freshers. You can find thousands of fresher jobs in India on this website.You can apply for these jobs directly.</p>
          <p>Hireclap.com is the best place to get quick updates about popular job opportunities in India. We provide job alerts for major companies, government, training, upskilling, competitive exams etc. This is a completely free service that is being provided for job seekers. You only have to register with your basic details and can find jobs matching your profile. To get free job alerts for the latest jobs in 2022, register on Hireclap.com.</p>
          <p>The job list on your recommend job sections are updated regularly and you will also get alerts for the latest jobs posted on our site. You can access the site through your mobile phone and get the latest jobs at your fingertips. We provide only verified and 100% guaranteed jobs on our platform. You can easily find matching jobs from <a target="_blank" href="https://www.hireclap.com/jobs-by-categories">Jobs by category</a>, <a target="_blank" href="https://www.hireclap.com/jobs-by-cities">Jobs by City</a>, <a target="_blank" href="https://www.hireclap.com/jobs-by-courses">Jobs by courses</a> and <a target="_blank" href="https://www.hireclap.com/jobs-by-roles">Jobs by role</a>.</p>
          <p>Jobs published on our website are posted by reputed companies and it's verified by before approval. Join our social media platforms Linkedin, Youtube, Instagram, Facebook and Twitter to get alerts.Hireclap.com has earned the reputation of being the largest video profile-based fresher job site in India in the past few years with many candidates placed in major Companies, MNCs and Startups via campus, off-campus recruitments & job fairs.</p>
        </div>
        <div class="mb-7 p-4 bg-white boxRadius2 shadow2 faq">
          <div style="font-size:1.25rem;font-weight:500;" class="mb-1">Jobs for freshers - FAQs</div>
          <h4 class="question text-left" style="font-size:14px" v-b-toggle.collapse-a>Can I find only fresher jobs here</h4>
          <b-collapse id="collapse-a" class="answer"><p> Most of the jobs published are for freshers. We are listing experience jobs also but that is lesser in count. Most of the jobs are for freshers/entry-level looking candidates with 0 or with little experiecne of 6 months up to a maximum of 3 years experience.</p></b-collapse>
          <h5 class="question" style="font-size:14px" v-b-toggle.collapse-b>Are the jobs shown here Genuine</h5>
          <b-collapse id="collapse-b" class="answer">
            <p>Yes,We allow only verified and trusted companies/recruiters to post jobs. Also, every job posted here gets reviewed before publishing by our team to ensure the genuity of the job posting.</p>
          </b-collapse>
          <div class="question" v-b-toggle.collapse-c>How do I find the right job for me</div>
          <b-collapse id="collapse-c" class="answer">
            <p> You can filter the jobs listed based on your highest qualification course, your preferred location, your preferred role etc. You can find recommended jobs in your profile section, based on your location & the preferred role that you entered during registration. </p>
          </b-collapse>
          <h6 class="question" style="font-size:14px" v-b-toggle.collapse-d>How can I apply for a fresher job</h6>
          <b-collapse id="collapse-d" class="answer">
            <p> You can apply for a job by clicking on the apply now button on the job page. If you are new to our website, register first before applying for jobs.</p>
          </b-collapse>
        </div>
        <div class="p-3 mt-5">
          <div style="font-size:1.25rem;font-weight:500;" class="mb-1"><strong>Candidate testimonials</strong></div>
          <v-carousel hide-delimiter-background show-arrows-on-hover cycle>
            <v-carousel-item>
              <div class="mt-5">
                <p>“I applied via Hireclap.com. Thank you for the opportunity. That gives me a big chance in my life to work as an 'Area Sales Representative' in a reputed company“</p>
                <p><strong>Bismi N, Placed in Reliance</strong></p>
              </div>
            </v-carousel-item>
            <v-carousel-item>
              <div class="mt-5">
                <p>“Applied for campus recruitment process via Hireclap.I am grateful to Hireclap.com for helping me get my first job. I was able to attend interviews with several major companies via Hireclap and land at ICICI. Many thanks to Hireclap for assisting me to find my first job “</p>
                <p><strong>Swetha M, Placed in ICICI</strong></p>
              </div>
            </v-carousel-item>
            <v-carousel-item>
              <div class="mt-5">
                <p>“Applied for campus recruitment process via Hireclap.I am grateful to Hireclap.com for helping me get my first job. I was able to attend interviews with several major companies via Hireclap and land at ICICI. Many thanks to Hireclap for assisting me to find my first job “</p>
                <p><strong>Swetha M, Placed in ICICI</strong></p>
              </div>
            </v-carousel-item>
            <v-carousel-item>
              <div class="mt-5">
                <p>“ It is a good site for freshers. The whole process of registration, finding a job and applying is very easy and simple. Jobseekers can find the latest genuine entry-level jobs here.”</p>
                <p><strong>Ayush J , Placed in Toshiba</strong></p>
              </div>
            </v-carousel-item>
          </v-carousel>
        </div>
    </div>
  </div>
</template>

<script>
import '../assets/hire.css'
import validation from '../libraries/validFormat' ;
import { mapState, mapGetters } from 'vuex';
import { COURSES } from '../store/modules/courses/constants';
import { STATES } from '../store/modules/states/constants';
import { JOB_ROLE } from '../store/modules/jobrole/constants';
import store from '../store';
import Actions from '../libraries/apiActions.js';
import { ContentLoader } from 'vue-content-loader';

export default ({
  name: "JobList",
  components: {
    ContentLoader,
  },
   metaInfo: (vue) => {
    const seo_title = vue.seo_title;
    const meta_desc = vue.meta_desc;
    const meta_keyword = vue.meta_keyword;

     return{
       title: seo_title ? seo_title : 'Fresher jobs in India | Jobs For Freshers in India | Latest Jobs for Freshers in India',
       meta:[
        {name:'description',content: meta_desc ? meta_desc : 'Jobs For Freshers: Search & Apply for the Latest Fresher Jobs for Graduates & Post Graduates. New Job Postings Everyday. India\'s Best Job Site'},
        {name:'keywords',content: meta_keyword ? meta_keyword : 'Jobs,Entry Level jobs, freshers opening, IT, Software, Core Technical ,Government , Defence , Research , Internship, BPO, MBA, Teaching, Pharma, StartUp, Diploma , BBM, BCA , BSc, Scholarships, Dream, Walkin , 1 to 3 Yr Exp jobs,jobs in Bangalore, pune, mumbai,Kolkata, Chennai, Delhi,  B.tech freshers job, freshers IT jobs, Btech,MBA,mechanical, electrical, engineering jobs'},
        {property: 'og:title', content: 'Fresher jobs in India | Jobs For Freshers in India | Latest Jobs for Freshers in India'},
        {property: 'og:type', content: 'website'},
        {property: 'og:url', content: window.location.href},
        {property: 'og:description', content: 'Jobs For Freshers: Search & Apply for the Latest Fresher Jobs for Graduates & Post Graduates. New Job Postings Everyday. India\'s Best Job Site'},
        {property: 'og:image', content: 'https://d22r5huxahsjvt.cloudfront.net/Assets/images/hireclap-logo-HD.png'},
        {property: 'og:site_name', content: 'hireclap.com'},
       ],
       link: [
         { rel: 'canonical', href: process.env.VUE_APP_URL+window.location.pathname.replace('/',"")},
       ]
     }
  },
  beforeMount() {
    this.isLogin = (this.$cookies.get("hire-seeker")&&this.$cookies.get("hire-seeker").token) ? true:false;
    this.getJobList();
  },
  beforeCreate() {
      this.$store = store;
  },
  created() {
    const apiFn = this.action;
    this.cachedDispatch(COURSES.GET_COURSE, { param: apiFn });
    this.cachedDispatch(STATES.GET_STATES, { param: apiFn });
    this.cachedDispatch(JOB_ROLE.GET_JOB_ROLE, { param: apiFn });
  },
  computed: {
    ...mapGetters(['cachedDispatch']),
    ...mapState({
        courses: state => state.courses.courses.courses,
        locations: state => state.states.locations.locations,
        jobrole: state => state.jobrole.jobrole.jobrole
    })
  },
  data() {
    return {
      validate: new validation(),
      action: new Actions(),
      jobs:[],
      seo_title: '',
      meta_desc: '',
      meta_keyword: '',
      job_head: '',
      job_name: '',
      loading:true,
      // jobrole:[],
      // courses:[],
      f_jobrole:[],
      f_exp:[],
      f_qualification:[],
      f_locations:[],
      // locations:[],
      meta:null,
      pages:[],
      page:1,
      length: 0,
      perPage:25,
      filter:{
        job_role:null,
        city_id:null,
        experience_from:0,
        experience_to:12,
        salary_range_from:null,
        course_id:null,
        search:null,
        offset:0,
      },
      selectedRole:null,
      selectedLocation:null,
      filterd:false,
      limit: 25,
      showJobrole:false,
      showExperience:false,
      showSalary:false,
      job_url:null,
      top_content:null,
      bottom_content:null,
      isLogin:false
    }
  },
  methods:{
    setHeader(){
      this.filterd = (this.filter.jobrole||this.filter.city_id)?true:false;
      this.jobrole.forEach(ele => {
        if(this.filter.jobrole && ele.value == this.filter.jobrole){
          this.selectedRole = ele.text
        }
      })
      this.locations.forEach(loc => {
        if(loc.id && loc.id == this.filter.city_id){
          this.selectedLocation = loc.text
        }
      })
    },
    // handlePageChange(value) {
    //   this.page = value;
    //   this.filter.offset = this.limit * (this.page - 1);
    //   this.getJobList();
    //   window.scrollTo(0, 250);
    // },
    loadMore() {
      this.filter.offset += this.limit;
      this.action.getJobList(this.filter).then((res) => {
        res.jobs.forEach(job => {
          job.last_date = this.validate.convertTimestamp(job.last_date);
          // job.date_of_posting = this.validate.timeDifference(job.date_of_posting);
        })
        this.jobs = this.jobs.concat(res.jobs);
        this.limit = res.meta.limit;
        this.filter.offset = res.meta.offset;
      })

    },
    getJobList(){
      this.pages=[];
      this.perPage=25;
      this.action.getJobList(this.filter).then((res) => {
        this.jobs = res.jobs;
        this.meta = res.meta;
        
        this.limit = res.meta.limit;
        this.filter.offset = res.meta.offset;
        this.length = Math.ceil(res.meta.total/res.meta.limit);

        this.jobs.forEach(job => {
          job.last_date = this.validate.convertTimestamp(job.last_date);
          let param = job.job_url?job.job_url:'';
          param = param.replace(/[^A-Z0-9]+/ig, "-");
          if(param.length > 80){
            let param_split = param.split("-");
            let id_length = param_split[param_split.length-1].length;
            param = param.substring(0, 80-(id_length+1));
            param = param+'-'+param_split[param_split.length-1];
          }
          job.param = param;
          // job.date_of_posting = this.validate.timeDifference(job.date_of_posting);
        })
        this.loading = false;
      })
    },
    filterRole(e){
      if(e.target.value!=''){
        this.jobrole.forEach(role => {
          if(role.text.match(e.target.value)){
            this.f_.push(role);
          }else{
            this.f_jobrole=[];
          }
        });
      }else{
        this.f_jobrole = this.jobrole;
      }
    },
    async copyToClipboard(job, index){
      let param = job.job_url?job.job_url:'';
      param = param.replace(/[^A-Z0-9]+/ig, "-");
      if(param.length > 80){
        let param_split = param.split("-");
        let id_length = param_split[param_split.length-1].length;
        param = param.substring(0, 80-(id_length+1));
        param = param+'-'+param_split[param_split.length-1];
      }
      await navigator.clipboard.writeText(process.env.VUE_APP_URL_WS_HTTP+'/jobs/'+param);
      document.getElementById('copy-'+index).style.display = "inline";
      setTimeout(() =>{
        document.getElementById('copy-'+index).style.display = "none";
      },3000)
    },
    setRoleFilter(e){
      e.preventDefault();
      this.filter.job_role = e.target.value;
      this.getJobList();
      e.target.parentNode.parentNode.previousSibling.value = e.target.textContent;
      this.f_jobrole = [];
    },
    setExpFilter(e){
      this.filter.experience_from = e.target.value;
      this.getJobList();
      e.target.parentNode.parentNode.previousSibling.value = e.target.textContent;
      this.f_exp = [];
    },
    setSalaryFilter(e){
      this.filter.salary_range_from = e.target.value;
      this.getJobList();
      e.target.parentNode.parentNode.previousSibling.value = e.target.textContent;
    },
    submitFilter(e){
        e.preventDefault();
        var search_query = {};
        search_query.redirect_from = 'fresher_jobs';
        if(this.filter.search){
          search_query.search = this.filter.search
        }
        if(this.filter.course_id){
          search_query.course_id = this.filter.course_id
        }
        if(this.filter.city_id){
          search_query.city_id = this.filter.city_id
        }
        if(this.filter.job_role){
          search_query.role_id = this.filter.job_role
        }
        if(this.filter.experience_from){
          search_query.experience_from = this.filter.experience_from
        }
        if(this.filter.experience_to){
          search_query.experience_to = this.filter.experience_to
        }
        this.$router.push({ path: '/jobsearch', query: search_query });
        this.getJobList();
        this.setHeader();
      
    },
    clearFilter(e){
      e.preventDefault();
      this.filter.search = null;
      this.filter.course_id = null;
      this.filter.city_id = null;
      this.filter.job_role = null;
      this.resetFilter();
    },
    resetFilter(){
      if(this.$route.path == '/jobs-for-freshers'){
        this.$router.push('/jobs-for-freshers')
      }
      location.reload();
    },
    share(job){
      let param = (job.job_designation)? job.job_designation:'';
      param += job.cities?(job.job_designation)?('-'+job.cities[0]):job.cities[0]:'';
      param += job.company_name? (job.cities ||job.job_designation) ?'-'+job.company_name:job.company_name:'';
      param += job.job_id?(job.cities ||job.job_designation||job.company_name)?'-'+job.job_id:job.job_id:'';

      param = param.replace(/[^A-Z0-9]+/ig, "-");
      var currentUrl = this.$route.currentUrl;
      console.log('http://www.facebook.com/share.php?u=' + currentUrl+'/'+param);
        // document.getElementById('fb_share').href ='http://www.facebook.com/share.php?u=' + encodeURIComponent(location.href)+'/'+param; 
      // this.$router.replace('https://www.facebook.com/share.php?u='+currentUrl+'/'+param);
    }
  }
})
</script>
